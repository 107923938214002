<template>
    <!-- Блок обраних клієнтів -->
    
    <b-row :key="componentKey">
        <b-col lg="6">
            <div class="card card-height-100">
                <div class="card-header align-items-center d-flex" >
                    <h4 class="card-title mb-0 flex-grow-1 fw-semibold">{{ $t('FeaturedClients') }} <span class="badge badge-soft-info">{{ accounts.length }}</span></h4>
                    <div class="flex-shrink-0" >
                        <button type="button" class="btn btn-soft-primary btn-sm fw-semibold" @click="clearNumbers()">
                            {{ $t('Clear') }}
                        </button>
                    </div>
                </div>

                <!-- Список клієнтів -->
                <div class="list-group" v-if="accounts.length > 0">
                    <template v-for="item in accounts" :key="item">
                        <a 
                            class="list-group-item list-group-item-action copy" 
                            @click="open(item)" 
                            @contextmenu.prevent="showContextMenu($event, item)"
                        >
                            <!-- Рівень адекватності -->
                            <div class="float-end">
                                <span :class="mutateAdequacyClient(item.adequacy).oc">{{ mutateAdequacyClient(item.adequacy).name }}</span>
                            </div>

                            <div class="d-flex mb-2 align-items-center">
                                <div class="flex-grow-1">
                                    <!-- Номер телефону -->
                                    <h5 class="list-title fs-15 mb-1">{{ item.userPhone }}</h5>
                                    <!-- Дата додавання номеру в обрані -->
                                    <p class="list-text mb-0 fs-12">{{ item.date }}</p>
                                </div>
                            </div>
                            <!-- Коментар -->
                            <p class="list-text mb-0">{{ item.comment }}</p>
                        </a>
                    </template>
                </div>

                <!-- Блок для відображення, якщо немає номерів -->
                <div v-else >
                    <div class="tab-pane fade p-4 active show" id="alerts-tab" role="tabpanel" aria-labelledby="alerts-tab">
                        <div class="empty-notification-elem">
                            <div class="text-center pb-5 mt-2">
                                <h6 class="fs-18 fw-semibold lh-base">{{ $t('notHaveFavoriteCustomerNumbers') }}</h6>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </b-col>
    </b-row>

    <!-- Контекстне меню -->
    <my-context-menu 
        ref="contextMenu" 
        :btn="btnContextMenu"
        @select="switchCntMenu"
    />

    <!-- Модальне вікно додавання клієнта в обрані -->
    <addFavorite
        v-if="objFavorite.show"
        @close="objFavorite.show = false"
        :obj="this.form"
        :objForm="objFavorite"
        @changeFav="checkFavorite = true"
    />

</template>

<script>
import MyContextMenu from '@/components/contextMenu/index.vue'; // компонент контекст-меню
import addFavorite from '@/views/dashboard/favorites/add' // картка додавання номеру в обрані
import { mutateAdequacy } from "@/usabilityScripts/globalMutate";
import {copy} from '@/usabilityScripts/copy.js'

export default{
    components: {
        MyContextMenu,
        addFavorite
    },
    data(){
        return{
            form:'',
            accounts: [], // масив акаунтів
            componentKey: '', // для перерендеру 
            objFavorite: {
                show: false,
                obj: {},
                permEdit: false,
            },
            btnContextMenu: [ // Параметри для контекстного меню
                { 
                    title: this.$t('Open'),
                    icon: 'bx bxs-door-open',
                    color: 'info',
                    value: 'open',
                    status: true,
                    perms: null
                },
                { 
                    title: this.$t('copyNum'),
                    icon: 'bx bx-copy-alt',
                    color: 'info',
                    value: 'copyNum',
                    status: true,
                    perms: null
                },
                { 
                    title: this.$t('toEditAp'),
                    icon: 'bx bx-edit',
                    color: 'info',
                    value: 'toEditAp',
                    status: true,
                    perms: null
                },
                { 
                    title: this.$t('Delete'),
                    icon: 'bx bx-trash',
                    color: 'danger',
                    value: 'Delete',
                    status: true,
                    perms: null
                },
            ]
        }
    },
    methods: {
        mutateAdequacyClient(e){
            return mutateAdequacy(e)
        },
        clearNumbers(){
            // Очистити номери
            localStorage.removeItem('favoriteAccounts')
            this.accounts = []
            this.$toast.success(this.$t('Cleared'))
        },
        open(e){
            // Відкрити картку клієнта
            this.eventBus.emit('openCardClient', {
                status: true,
                method: 'phone',
                phone: e.userPhone,
            })
        },
        showContextMenu(event, item) {
            // Метод, який викликається при правому кліку на елементі, передаючи подію та об'єкт item

            // Викликаємо метод показу контекстного меню після оновлення DOM
            this.$nextTick(() => {
                // Виклик методу показу контекстного меню у компоненті contextMenu,
                // передаючи подію та об'єкт item
                this.$refs.contextMenu.showContextMenu(event, item);
            });
        },
        switchCntMenu(e, item){
            // Виконуємо обрану дію з контекстного меню
            switch (e) {
                case 'open':
                    // Відкрити компанію
                    this.open(item)
                    break;
                case 'copyNum':
                    // Відкрити компанію
                    this.copy(item.userPhone)
                    break;
                case 'toEditAp':
                    // Відкрити компанію
                    this.objFavorite.obj = item;
                    this.objFavorite.permEdit = true;
                    this.objFavorite.show = true;
                    break;
                case 'Delete':
                    // Відкрити компанію
                    this.removeFavotire(item)
                    break;
                
                default:
                    break;
            }
        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
        removeFavotire(e){
            // Видаляємо акаунт з обраних

            let accounts = JSON.parse(localStorage.getItem('favoriteAccounts')) || [];

            let targetUserPhone = e.userPhone;

            // Пошук індексу обраного акаунта за userPhone
            let indexToRemove = accounts.findIndex(acc => acc.userPhone === targetUserPhone);

            if (indexToRemove !== -1) {
                // Видаляємо акаунт за індексом
                accounts.splice(indexToRemove, 1);
                localStorage.setItem('favoriteAccounts', JSON.stringify(accounts));
                this.accounts = JSON.parse(localStorage.getItem('favoriteAccounts')) || [];
                this.$toast.success("numberRemovedfromFavorites");
                this.componentKey += 1
            } else {
                this.$toast.error("AccountNotFoundinFavorites");
            }
        },
    },
    mounted(){
        this.accounts = JSON.parse(localStorage.getItem('favoriteAccounts')) || [];
    }
}

</script>