<template>
    <PageHeader :title="title" />
        <div class="row">
            <div class="flex-grow-1">
                <h4 class="fs-16 mb-2">
                    {{ this.dataGreeting() }} {{user.name}}!
                </h4>

                <template v-if="this.user.userid == '34' || this.user.userid == '32' || this.user.userid == '43' || this.user.userid == '52'">
                    <listFavorite />
                </template>
                
                <template v-else>

                    
                    <div id="step1">
                        <h3 class="fs-20 fw-bold mb-1 border-bottom pb-2 mb-3" >{{ $t('Addendum') }}</h3>
                        <b-row>
                            <template v-for="item in blockCreated" :key="item">
                                <b-col xl="3" v-if="isShowItem(item)">
                                    <div class="card card-animate" style="cursor: pointer;" @click="this[item.showModal] = true">
                                        <div class="card-body">
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <p class="fw-semibold text-muted mb-0" style="word-break: break-all;">{{ item.menu }}</p>
                                                    <h2 class="mt-4 ff-secondary fw-semibold" style="word-break: break-all;">{{ item.name }}</h2>
                                                </div>
                                                <div>
                                                    <div class="avatar-sm flex-shrink-0">
                                                        <span
                                                            :class="`avatar-title bg-${item.color} text-white rounded-circle fs-4`"
                                                        >
                                                            <i :class="item.icon"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-col>
                            </template>
                        </b-row>
                    </div>
                    <hr />
                    <div id="step2">
                        <h3 class="fs-20 fw-bold mb-1 border-bottom pb-2 mb-3">{{ $t('FastPassage') }}</h3>
                        <b-row>
                            <template v-for="item in blockTr" :key="item">
                                <b-col xl="3" v-if="isShowItem(item)">
                                    <router-link :to="item.path">
                                        <div class="card card-animate">
                                            <div class="card-body">
                                                <div class="d-flex justify-content-between">
                                                    <div>
                                                        <p class="fw-semibold text-muted mb-0" style="word-break: break-all;">{{ item.menu }}</p>
                                                        <h2 class="mt-4 ff-secondary fw-semibold" style="word-break: break-all;">{{ item.name }}</h2>
                                                    </div>
                                                    <div>
                                                        <div class="avatar-sm flex-shrink-0">
                                                            <span
                                                                :class="`avatar-title bg-soft-${item.color} text-${item.color} rounded-circle fs-4`"
                                                            >
                                                                <i :class="item.icon"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                </b-col>
                            </template>
                        </b-row>
                    </div>
                </template>
            </div>
        </div>
        
        <!-- тест календаря -->
        <!-- <calendar v-if="this.user.userid === 42 || this.user.userid === 33"></calendar> -->

    <!-- добавить заявку -->
    <createapplicBox v-if="modalRequestBox" @close="this.modalRequestBox = false" @open = "open" :perm = "this.applicPerm" :obj = "this.obj"></createapplicBox>
    <!-- добавить заявку -->

    <!-- добавить задачу -->
    <createTaskBox v-if="modalTaskBox" @close="this.modalTaskBox = false" @open = "open" :obj="this.obj" :perm="this.taskPerm"></createTaskBox>
    <!-- добавить задачу -->

    <!-- добавить тикет -->
    <createTicketBox v-if="modalTicketBox" @close="this.modalTicketBox = false" @open = "open" :perm="ticketPerm" :obj="this.obj"></createTicketBox>
    <!-- добавить тикет -->

    <!-- добавить проект -->
    <createProjectBox v-if="modalProjectBox" @close="this.modalProjectBox = false" @open = "open" :editform="this.obj" :newform = "projectPerm"></createProjectBox>
    <!-- добавить проект -->

    <!-- добавить счет -->
    <createInvoicesBox v-if="modalInvoicesBox" @close="this.modalInvoicesBox = false" @open = "open" :perm = "this.invPerm" :obj = "this.obj"></createInvoicesBox>
    <!-- добавить счет -->

    <!-- добавить урок -->
    <createPostBox v-if="modalPostBox" @close="this.modalPostBox = false" @open = "open"></createPostBox>
    <!-- добавить урок -->

    <!-- добавить faq -->
    <createFaqBox v-if="modalFaqBox" @close="this.modalFaqBox = false" @open = "open"></createFaqBox>
    <!-- добавить faq -->

  <viewapplic
    v-if="showApplic == true"
    @close="showApplic=false"
    @edit="editApplic"
    :shortapplic = "this.obj"
  ></viewapplic>

  <viewinvoice
    v-if="this.showInvoice == true"
    @close="this.showInvoice = false"
    @edit = "editInv"
    :obj="this.obj"
  ></viewinvoice>

  <viewticket
    v-if="showTicket == true"
    @close="showTicket = false"
    :obj="this.obj"
    @edit = "editTicket"
  ></viewticket>

  <viewproject
    v-if="showProject == true"
    @close="showProject = false"
    @edit="editProject" 
    :obj="this.obj"
  ></viewproject>
  
  <viewtask
    v-if="showTask == true"
    @close = "showTask = false"
    @edit = "editTask"
    :shortapplic="this.obj"
  ></viewtask>

</template>

<script>
import calendar from "@/components/calendar.vue"
import PageHeader from "@/components/page-header";
import createapplicBox from '@/views/application/createapplic.vue'
import createTaskBox from '@/views/tasks/createtask.vue'
import createTicketBox from '@/views/newtickets/createticket.vue'
import createProjectBox from '@/views/projects/new.vue'
import createInvoicesBox from "@/views/invoices/createinvoices.vue"
import createPostBox from "@/components/knowledge-base/actions/addPost"
import createFaqBox from "@/components/faq/actions/addPost"
import { storeS } from "@/store.js"
import viewapplic from "../../views/application/view/viewapplic.vue"
import viewinvoice from "../../views/invoices/view.vue"
import viewticket from "../../views/newtickets/view/index.vue"
import viewpresentation from "../../views/presentations/view/index"
import viewproject from "../../views/projects/view/index"
import viewtask from "../../views/tasks/view/viewtask"
import listFavorite from "./listFavotire"

export default ({
    components: {
        calendar,
        PageHeader,
        createapplicBox,
        createTaskBox,
        createTicketBox,
        createProjectBox,
        createInvoicesBox,
        createPostBox,
        createFaqBox,
        viewapplic, 
        viewinvoice, 
        viewticket, 
        viewproject, 
        viewtask,
        listFavorite
    },
    data() {
        return {
            tour: null,
            invPerm: false,
            taskPerm: false,
            projectPerm: true,
            applicPerm: false,
            showApplic: false,
            showInvoice: false,
            ticketPerm: false,
            showProject: false,
            showTask: false,
            showTicket: false,
            obj: '',
            title: this.$t('startPage'),
            modalRequestBox: false,
            modalTaskBox: false,
            modalTicketBox: false,
            modalProjectBox: false,
            modalInvoicesBox: false,
            modalPostBox: false,
            modalFaqBox: false,
            form: {},
            blockCreated: [
                {
                    name: this.$t('Add'),
                    menu: this.$t('applications'),
                    showModal: "modalRequestBox",
                    icon: 'ri-phone-fill',
                    color: 'success',
                    perm: 101
                },
                {
                    name: this.$t('Add'),
                    menu: this.$t('tasks'),
                    showModal: "modalTaskBox",
                    icon: 'ri-task-line',
                    color: 'warning',
                    perm: 201
                },
                {
                    name: this.$t('Add'),
                    menu: this.$t('tickets'),
                    showModal: "modalTicketBox",
                    icon: 'ri-ticket-2-line',
                    color: 'danger',
                    perm: 301
                },
                {
                    name: this.$t('Add'),
                    menu: this.$t('navProjects'),
                    showModal: "modalProjectBox",
                    icon: 'ri-stack-fill',
                    color: 'secondary',
                    perm: 351
                },
                {
                    name: this.$t('Add'),
                    menu: this.$t('invoices'),
                    showModal: "modalInvoicesBox",
                    icon: 'bx bx-wallet',
                    color: 'info',
                    perm: 1701
                },
                {
                    name: this.$t('Add'),
                    menu: this.$t('Knowledge_base'),
                    showModal: "modalPostBox",
                    icon: 'bx bx-brain',
                    color: 'info',
                    perm: 802
                },
                {
                    name: this.$t('Add'),
                    menu: this.$t('qua_and_ans'),
                    showModal: "modalFaqBox",
                    icon: 'bx bxs-info-circle',
                    color: 'info',
                    perm: 1001
                }
            ],
            blockTr: [
                {
                    name: this.$t('calls_all'),
                    menu: this.$t('applications'),
                    path: '/calls/all',
                    icon: 'ri-phone-fill',
                    color: 'success',
                    perm: 100
                },
                {
                    name: this.$t('tasks'),
                    menu: this.$t('tasks'),
                    path: '/tasks/all',
                    icon: 'ri-task-line',
                    color: 'warning',
                    perm: 200
                },
                {
                    name: this.$t('tickets'),
                    menu: this.$t('tickets'),
                    path: '/newtickets/all',
                    icon: 'ri-ticket-2-line',
                    color: 'danger',
                    perm: 300
                },
                {
                    name: this.$t('navProjects'),
                    menu: this.$t('navProjects'),
                    path: '/projects/all',
                    icon: 'ri-stack-fill',
                    color: 'secondary',
                    perm: 350
                },
                {
                    name: this.$t('invoices'),
                    menu: this.$t('invoices'),
                    path: '/invoices/all',
                    icon: 'bx bx-wallet',
                    color: 'info',
                    perm: 1700
                },
                {
                    name: this.$t('Knowledge_base'),
                    menu: this.$t('Knowledge_base'),
                    path: '/knowledge-base/KnowledgeBase',
                    icon: 'bx bx-brain',
                    color: 'info',
                    perm: 802
                },
                {
                    name: this.$t('qua_and_ans'),
                    menu: this.$t('qua_and_ans'),
                    path: '/faq/faq',
                    icon: 'bx bxs-info-circle',
                    color: 'info',
                    perm: 1001
                },
                {
                    name: this.$t('Employees'),
                    menu: this.$t('Employees'),
                    path: '/users',
                    icon: 'ri-team-fill',
                    color: 'info',
                    perm: 700
                }
            ]
        };
    },
    created(){
    },
    computed: {
        user() {
            return storeS.userbase
        },
        perms() {
            return storeS.perms
        },
        tours(){
            return storeS.tours
        }
    },
    methods: {
        createTour(){
            this.tour = this.$shepherd({
                useModalOverlay: true
            });
            const step1 = document.getElementById('step1');
            this.tour.addStep({
                attachTo: { element: step1, on: 'top' },
                text: 'Вітаємо в дашборд. На головні сторінці ви можете швидко заявку, тикет, проект та інше.',
                buttons: [
                    {
                        action: this.tour.next,
                        text: this.$t('Next')
                    }
                ],
            });
            const step2 = document.getElementById('step2');
            
            this.tour.addStep({
                id: step2,
                text: 'У цьому блоці швидко перейти у потрібний розділ. Якщо є питання, зверніться до Бориса, або поверніться до кроку 1.',
                attachTo: {
                    element: step2,
                    on: 'bottom'
                },
                buttons: [
                    {
                        action() {
                            return this.back();
                        },
                        classes: 'shepherd-button-secondary',
                        text: this.$t('back')
                    },
                    {
                        action() {
                            return this.complete();
                        },
                        classes: 'shepherd-button-secondary',
                        text: this.$t('toComplete')
                    },
                ]
            });

        },
        editApplic(e) {
            
            this.obj = e;
            this.showApplic = false
            this.applicPerm = true;
            this.modalRequestBox = true;
        },
        editInv(e) {
            ;
            this.obj = e
            this.showInvoice = false
            this.invPerm = true;
            this.modalInvoicesBox = true
        },
        editProject(e) {
            this.obj = e
            this.showProject = false
            this.projectPerm = false
            
            this.modalProjectBox = true
        },
        editTask(e) {
            this.taskPerm = true
            this.showTask = false
            this.obj = e;
            this.modalTaskBox = true
            
        },
        editTicket(e) {
            this.obj = e
            this.showTicket = false
            this.ticketPerm = true
            
        },
        open(e, type) {
            switch(type) {
                case 'application':
                this.obj = e
                this.modalRequestBox = false
                this.showApplic = true    
                break;
                case 'task':
                this.obj = e
                this.modalTaskBox = false
                this.showTask = true
                break;
                case 'ticket':
                this.obj = e
                this.modalTicketBox = false
                this.showTicket = true
                break;
                case 'project':
                this.obj = e
                this.modalProjectBox = false
                this.showProject = true
                break;
                case 'invoice':
                this.obj = e
                this.modalInvoicesBox = false
                this.showInvoice = true
                break;
            }
        },
        isShowItem(option){
            return this.perms[option.perm];
        },
        dataGreeting() {
            let time = new Date().getHours();
            if (time >= 4 && time <= 11) {
                return this.$t('GoodMorning')+", ";
            } else if (time > 11 && time <= 15) {
                return this.$t('GoodAfternoon')+", ";
            } else if(time >= 16 && time <= 21) {
                return this.$t('GoodEvening')+", "
            } else {
                return this.$t('GoodNight')+", "
            }
        },
    },
    mounted(){
        // гайд
        if(this.tours[11]){
            this.createTour();
            this.tour.start();
        }
        // гайд
    },
})
</script>
